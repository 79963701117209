define("discourse/plugins/discourse-category-experts/discourse/components/endorsement-checkboxes", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _component, _object, _computed, _runloop, _ajax, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("saving", "selectedCategoryIds", "startingCategoryIds", "remainingEndorsements"), (_obj = {
    user: null,
    saving: false,
    categories: null,
    endorsements: null,
    selectedCategoryIds: null,
    startingCategoryIds: null,
    showingSuccess: false,
    loading: true,
    remainingEndorsements: null,
    outOfEndorsements: (0, _computed.lt)("remainingEndorsements", 1),
    didInsertElement() {
      this._super(...arguments);
      if (!this.endorsements) {
        this.set("endorsements", []);
      }
      this.set("startingCategoryIds", this.endorsements.map(e => e.category_id));
      (0, _ajax.ajax)(`/category-experts/endorsable-categories/${this.user.username}.json`).then(response => {
        this.setProperties({
          remainingEndorsements: response.extras.remaining_endorsements,
          categories: response.categories,
          selectedCategoryIds: [...this.startingCategoryIds],
          loading: false
        });
        (0, _runloop.next)(() => {
          this.endorsements.forEach(endorsement => {
            const checkbox = this.element.querySelector(`#category-endorsement-${endorsement.category_id}`);
            if (checkbox) {
              checkbox.checked = true;
              checkbox.disabled = true;
            }
          });
        });
      }).catch(_ajaxError.popupAjaxError);
    },
    saveDisabled(saving, categoryIds, startingCategoryIds, remainingEndorsements) {
      if (remainingEndorsements === 0 || saving || !categoryIds || categoryIds.length === 0 && startingCategoryIds.length === 0) {
        return true;
      }
      return !categoryIds.filter(c => !startingCategoryIds.includes(c)).length;
    },
    save() {
      if (this.saveDisabled) {
        return;
      }
      this.set("saving", true);
      (0, _ajax.ajax)(`/category-experts/endorse/${this.user.username}.json`, {
        type: "PUT",
        data: {
          categoryIds: this.selectedCategoryIds
        }
      }).then(response => {
        this.set("user.category_expert_endorsements", response.category_expert_endorsements);
        this.set("showingSuccess", true);
        (0, _runloop.later)(() => {
          this.afterSave();
          this.setProperties({
            showingSuccess: false,
            saving: false
          });
        }, 300);
        if (this.location) {
          this.appEvents.trigger("category-experts:endorsement-given", {
            location: this.location,
            user_id: this.currentUser.id,
            categoryIds: this.selectedCategoryIds,
            endorsed_user_id: this.user.id
          });
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    checkboxChanged(categoryId) {
      if (this.startingCategoryIds.includes(categoryId)) {
        return;
      }
      let checked;
      if (this.selectedCategoryIds.includes(categoryId)) {
        this.set("selectedCategoryIds", this.selectedCategoryIds.filter(id => id !== categoryId));
        checked = false;
      } else {
        this.set("selectedCategoryIds", [...this.selectedCategoryIds].concat([categoryId]));
        checked = true;
      }
      (0, _runloop.next)(() => this.element.querySelector(`#category-endorsement-${categoryId}`).checked = checked);
    }
  }, (_applyDecoratedDescriptor(_obj, "saveDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "saveDisabled"), _obj), _applyDecoratedDescriptor(_obj, "save", [_object.action], Object.getOwnPropertyDescriptor(_obj, "save"), _obj), _applyDecoratedDescriptor(_obj, "checkboxChanged", [_object.action], Object.getOwnPropertyDescriptor(_obj, "checkboxChanged"), _obj)), _obj)));
});