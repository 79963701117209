define("discourse/plugins/discourse-category-experts/discourse/controllers/endorse-user", ["exports", "@ember/controller", "discourse/mixins/modal-functionality"], function (_exports, _controller, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, {
    onShow() {
      this.set("afterSave", () => this.send("closeModal"));
    }
  });
});