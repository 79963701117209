define("discourse/plugins/discourse-category-experts/discourse/templates/components/is-question-checkbox", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if show}}
    <label class="checkbox-label is-category-expert-question">
      <Input @type="checkbox" @checked={{model.is_category_expert_question}} />
      {{i18n "category_experts.ask_category_expert"}}
    </label>
  {{/if}}
  */
  {
    "id": "wY6RGacQ",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[10,\"label\"],[14,0,\"checkbox-label is-category-expert-question\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"model\",\"is_category_expert_question\"]]]],null],[1,\"\\n    \"],[1,[28,[35,2],[\"category_experts.ask_category_expert\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `show` property path was used in the `discourse/plugins/discourse-category-experts/discourse/templates/components/is-question-checkbox.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.show}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-category-experts/discourse/templates/components/is-question-checkbox.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"input\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/templates/components/is-question-checkbox.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});