define("discourse/plugins/discourse-category-experts/discourse/components/is-question-checkbox", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("model", "model.category"), (_obj = {
    init() {
      this._super(...arguments);
      if ((this.model.creatingTopic || this.model.editingFirstPost) && this.model.topic && this.model.topic.is_category_expert_question) {
        this.set("model.is_category_expert_question", true);
      }
    },
    show(model, category) {
      if (!category || !category.allowingCategoryExpertQuestions) {
        return false;
      }
      return model.editingFirstPost || model.creatingTopic;
    }
  }, (_applyDecoratedDescriptor(_obj, "show", [_dec], Object.getOwnPropertyDescriptor(_obj, "show"), _obj)), _obj)));
});